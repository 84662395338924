import React from 'react';
import Modal from 'react-modal';
import { Map, Marker, TileLayer } from 'react-leaflet'
import { divIcon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Place from './Place.js'
import './StationsMap.css'
import 'leaflet'  // this, locatecontrol, and declaring capital L should be
// replaced with a locatecontrol react component. As of writing this,
//the component existed but was not compatible with react-leaflet@2
import 'leaflet.locatecontrol'
import Control from 'react-leaflet-control';
import ReactDOMServer from 'react-dom/server';
import {DateTime} from 'luxon'
import { greatCircleDistance } from './utils.js'
import { parkedPeriodThreshold } from './historyAnalysis.js'
require('react-leaflet-markercluster/dist/styles.min.css');
const L = window['L'];

const locateOptions = {
  clickBehavior: {inView: 'setView', outOfView: 'setView'},
  circleStyle: {fillOpacity: 0.1},
  locateOptions: { maxZoom: 16 },
}

const MIN_ZOOM_SHOW_PLACES=11;

export default class StationsMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cities: {},
      places: {},
      bikeHistories: {},
      mapZoom: 12,
      mapCenter: L.latLng(52.296298, 20.9583575),
      mapBounds: null,
      selectedPlace: null, // when not null, id of the place to display in overlay
      originDataTimestamp: null,
      isLoading: false,
      showHistory: false,
    };
  }

  componentDidMount() {
    let lc = L.control.locate(locateOptions).addTo(
      this.refs.leafletMapComponent.leafletElement);
    lc.start();
    this._fetchData();
    let bounds = this.refs.leafletMapComponent.leafletElement.getBounds();
    this.setState({mapBounds: bounds});
    Modal.setAppElement('body');
  }


  _fetchData = () => {
    if (this.state.isLoading) {
      console.log("Loading in progress, not retrying");
      return;
    }
    console.log("Starting API queries");
    this.setState({isLoading: true});
    let src_promise = fetch('https://api.nextbike.net/maps/nextbike-live.json')
    .then(response => response.json().then(json => ({
      json: json,
      lastModified: this.getSourceDataTimestamp(response),
    })))
    .then(({ json, lastModified }) => this._handleNextbikeResponse(json, lastModified))
    .catch(error => {
      console.log(error);
      this.setState({isLoading: false});
    });

    src_promise.then(fetch('https://backend.bikes.purecode.pl/histories.json')
    .then(response => response.json())
    .then(json => this._handleBikeHistories(json))
    .catch(error => {
      console.log(error);
      this.setState({isLoading: false});
    }));
  };

  getSourceDataTimestamp = (response) => {
    try {
      if (!response.headers.has('Last-Modified')) {
        throw new Error("Missing Last-Modified header");
      }
      return DateTime.fromHTTP(response.headers.get('Last-Modified'));
    } catch(err) {
      console.log("Error getting a timestamp from source data: ", err)
      return DateTime.utc();
    }
  }



  _handleBikeHistories = (response) => {
    console.log("Handling history response");
    this.setState({'bikeHistories': response, isLoading: false});
  }

  distanceBetweenPlaces = (num1, num2) => {
    let p1 = this.state.places[num1];
    let p2 = this.state.places[num2];
    try {
      return greatCircleDistance(p1.lat, p1.lng, p2.lat, p2.lng)
    } catch(err) {
      console.log("Error calculating greatCircleDistance between", p1, " and ", p2);
      return;
    }
  }

  _getAllCities(liveDataResponse) {
    const countries = liveDataResponse.countries;
    const cities = {};
    for (const country of countries) {
      if (!country.cities) {
        continue;
      }
      const cities_in_country = [];
      for (const city of country.cities) {
        if (!city.uid) {
          console.log("Missing uid in " + city);
          continue;
        }
        if (!city.places || city.places.length === 0) {
          console.log("No places in city " + city.uid);
          continue;
        }
        if (!city.lat || !city.lng) {
          console.log("No location for city: " + city.uid);
          continue;
        }
        cities_in_country.push(city.uid);
        city.related_cities = cities_in_country;
        cities[city.uid] = city;
      }
    }
    return cities;
  }

  _getAllPlaces(cities) {
    const places = {};
    for (const city of Object.values(cities)) {
      for (const place of city.places) {
        place.city_uid = city.uid;
        places[place.uid] = place;
      }
    }
    return places
  }

  _handleNextbikeResponse = (response, lastModified) => {
    // console.log(response);
    // console.log(lastModified);
    console.log("Handling a Nextbike response");
    if (!response.countries) {
      console.log('ERROR: no countries field in response');
      return;
    }
    const cities = this._getAllCities(response);
    const places = this._getAllPlaces(cities);
    this.setState({cities: cities, places: places, originDataTimestamp: lastModified});
  };
  _handleMapMove = (e: Object) => {
    let new_zoom_level = e.target.getZoom();
    let new_bounds = e.target.getBounds();
    let new_center = e.target.getCenter();
    if (this.state.mapZoom === new_zoom_level && this.state.mapCenter.equals(new_center)
       && this.state.mapBounds.equals(new_bounds)) {
         console.log("map onMoveEnd callback fired, but no real changes. This results from a Leaflet bug");
         return;
       }
    console.log("Map moved. New coords: ", new_zoom_level, new_center, new_bounds);
    this.setState({mapZoom: new_zoom_level, mapCenter: new_center, mapBounds: new_bounds});
  }
  _handleCityMarkerClick = (e: L.MouseEvent) => {
    // console.log(e.target);
    let zoom = e.target.options.cityZoomLevel;
    // some cities have erroneous levels, like 0 or 1
    if (!zoom || zoom < MIN_ZOOM_SHOW_PLACES) {
      zoom = MIN_ZOOM_SHOW_PLACES;
    }
    this._moveMapTo(e.latlng, zoom);
  }

  _handlePlaceMarkerClick = (place_uid: Number, e: L.MouseEvent) => {
    this.setState({selectedPlace: place_uid});
  }

  closeModal = () => {
    this.setState({selectedPlace: null});
  }

  toggleShowHistory = () => {
    console.log('showHistory:', this.state.showHistory);
    this.setState({showHistory: !this.state.showHistory});
  }

  _moveMapTo(target_loc: L.latLng, zoom_level: number=null) {
    let new_state = {mapCenter: target_loc}
    if (zoom_level != null) {
      new_state['mapZoom'] = zoom_level;
    }
    this.setState(new_state);
  }

  _getMarkers() {
    if (this.state.mapZoom >= MIN_ZOOM_SHOW_PLACES && this.state.mapBounds) {
      let all_places = Object.values(this.state.places);
      const bounds = this.state.mapBounds;
      const places_in_bounds = all_places.filter(place => bounds.contains([place.lat, place.lng]));
      console.log('Places in bounds: ' + places_in_bounds.length);
      return ( <MarkerClusterGroup>
        { places_in_bounds.map((place) =>
          <PlaceMarker
            key={place.uid}
            place={place}
            onClick={this._handlePlaceMarkerClick.bind(this, place.uid)}/>) }
        </MarkerClusterGroup> );
      } else {
        return ( Object.entries(this.state.cities).map(([uid, city]) =>
        <CityMarker key={uid} city={city} onClick={this._handleCityMarkerClick}/>) )

      }
    }

  _reloadIcon() {
    if (this.state.isLoading === true) {
      return ( <i className="material-icons">hourglass_empty</i> );
    }
    else {
      return ( <i className="material-icons">refresh</i> );
    }
  }

  render() {
    let thresholds;
    if (this.state.selectedPlace != null && this.state.bikeHistories) {
      thresholds = parkedPeriodThreshold(this.state.places[this.state.selectedPlace],
        this.state.cities, this.state.bikeHistories);
    }
    return (
      <div id="stationMap">
      <Map center={this.state.mapCenter} zoom={this.state.mapZoom}
      onMoveend={this._handleMapMove}
      maxZoom={18}
      ref="leafletMapComponent"
      >
      <TileLayer
      attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {this._getMarkers()}
      <Control position="topleft" className="leaflet-control leaflet-bar">
        <a className="leaflet-bar-part leaflet-bar-part-single leaflet-bar-part-custom"
         title="Refresh data" href="#top" role="button" onClick={this._fetchData}>
         {this._reloadIcon()}
        </a>
      </Control>



      </Map>
      <Modal
      isOpen={this.state.selectedPlace != null}
      onRequestClose={this.closeModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      >
      <Place
      place = {this.state.selectedPlace ? this.state.places[this.state.selectedPlace] : null}
      bikeHistories = {this.state.bikeHistories}
      placeDistanceFunc = {this.distanceBetweenPlaces}
      originDataTimestamp = {this.state.originDataTimestamp}
      parkedPeriodThresholds = {thresholds}
      showHistory = {this.state.showHistory}
      toggleShowHistory = {this.toggleShowHistory}
      />
      </Modal>
      </div>
    );
  }
}

class CityMarker extends React.Component {
  render() {
    const city = this.props.city;
    const position = [city.lat, city.lng];
    return (
      <Marker
      position={position}
      cityZoomLevel={city.zoom}
      onClick={this.props.onClick}
      />
    )
  }
}

class PlaceMarkerIcon extends React.PureComponent {
  max_displayed_number() {
    return 20;
  }


  _color_for_count(num_bikes) {
    if(num_bikes === 0) {
      return "red";
    } else if (num_bikes < 5) {
      return "yellow";
    } else {
      return "green"
    }
  }

  render() {

    const max_num = this.max_displayed_number();
    const displayed_count = (this.props.bike_count >= max_num ? max_num+'+': this.props.bike_count);
    return(
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" className="svg-icon-svg" style={{width:'32px', height:'48px'}}>
      <path className="svg-icon-path" d="M 1 16 L 16 46 L 31 16 A 8 8 0 0 0 1 16 Z"
        strokeWidth="2" stroke={this._color_for_count(this.props.bike_count)} strokeOpacity="1" fill={this._color_for_count(this.props.bike_count)}
         fillOpacity="0.7">
      </path>

      <text textAnchor="middle" x="16" y="19.5" style={{fontSize: '16px'}} fill="rgba(0, 0, 0,1)">{displayed_count}</text>
    </svg> )
    // <circle class="svg-icon-circle" cx="16" cy="16" r="0" fill="rgb(255,255,255)" fill-opacity="1" stroke="rgb(0,102,255)" stroke-opacity="1" stroke-width="2"></circle>
  }
}

class PlaceMarker extends React.Component {
  // For performance reasons, we are pre-rendering a number of variants of the marker.
  // If the number of bikes >= PlaceMarkerIcon.max_displayed_number, we show this number with a '+'
   static place_icons_rendered = [...Array(PlaceMarkerIcon.prototype.max_displayed_number() + 1).keys()].map(num => {
    return ReactDOMServer.renderToString(<PlaceMarkerIcon bike_count={num}/>)
  });

  render() {
    const place = this.props.place;
    const position = [place.lat, place.lng];
    const bike_count = place.bike_list.length;
    const max_num = PlaceMarkerIcon.prototype.max_displayed_number();
    let num_capped = (bike_count >= max_num ? max_num : bike_count);
    // const icon = divIcon({
    //   className: 'map-label',
    //   html: `<span>Label Text</span>`
    // });
    return (
      <Marker
        position={position}
        onClick={this.props.onClick}
        icon={new divIcon({className: 'place_icon_red',
          html: PlaceMarker.place_icons_rendered[num_capped]})}
      />
    )
  }
}
