import {DateTime} from 'luxon'

export const STATION_UNKNOWN = -1;

function flatten(arr) {
  return [].concat(...arr);
}

function newest_timestamp(hist) {
  // finds the newest timestamp in historical data
  const timestamps = [];
  let num;
  for (num in hist) {
    timestamps.push(hist[num][0][0]);
  }
  return timestamps.sort()[timestamps.length - 1];
}

function getParkedSince(bikes, hist) {
  // for a list of bike numbers, and the history data, return a list of 'since when they are parked'
  // if a bike is not parked currently, it is not inclu?ded
  const parkedSince = [];
  let latest;
  bikes.forEach(bike => {
    if (!hist[bike] || hist[bike].length === 0)
      return;
    latest = hist[bike][0];
    if (latest[1] !== -1)
      parkedSince.push(latest[0]);
  })
  return parkedSince;
}

export function referenceBikesForPlace(place, cities) {
  const city = cities[place.city_uid];
  // collect all numbers of bikes in the given city and related cities
  const places = flatten(city.related_cities.map(id => cities[id].places));
  const bikes = flatten(places.map(place => place.bike_list)).map(bike => bike.number);
  return bikes;
}

export function parkedPeriodQuantile(bikes, histories, quantile) {
  const newest = newest_timestamp(histories);
  const parkedSince = getParkedSince(bikes, histories).sort();

  let quant_ind = parkedSince.length - Math.round(parkedSince.length * quantile);
  if (quant_ind === parkedSince.length)
    quant_ind -= 1;

  const value_ms = DateTime.fromISO(newest) - DateTime.fromISO(parkedSince[quant_ind]);
  return value_ms;
}

const one_hour = 1000 * 60 * 60;

export function parkedPeriodThreshold(place, cities, histories) {
  const quantile = 0.5;
  const bikes = referenceBikesForPlace(place, cities);
  let quantile_val = parkedPeriodQuantile(bikes, histories, quantile);
  if (!quantile_val)
    quantile_val = one_hour * 6;
  let t_low = Math.max(3 * quantile_val, one_hour * 3);
  return {
    t_low: t_low,
    t_high: 3*t_low,
  };
}

export function timeSinceLastLongTrip(bikeHist, originDataTimestamp, placeDistanceFunc) {
  const minTripDuration = 10 * 60 * 1000; // 10 min
  const minTripDistance = 500; // 500 meters
  if (!bikeHist)
    return null;
  if (bikeHist.length === 1) {
    return bikeHist[0][1] === STATION_UNKNOWN ? 0 : (originDataTimestamp-bikeHist[0][0])
  }

  let endOfLastLongTrip = bikeHist[0][0];
  let i = 1;
  while (i < bikeHist.length) {
    if (bikeHist[i-1][1] !== STATION_UNKNOWN && bikeHist[i][1] === STATION_UNKNOWN) {
      // trip between i+1 and i-1 (places), time given by i and i-1
      let tripDuration = DateTime.fromISO(bikeHist[i-1][0]) - DateTime.fromISO(bikeHist[i][0]);
      let tripStartPlace = (i + 1< bikeHist.length) ? bikeHist[i+1][1] : bikeHist[i-1][1];
      let tripDistance = placeDistanceFunc(bikeHist[i-1][1], tripStartPlace);
      if (tripDuration >= minTripDuration || tripDistance >= minTripDistance)
        break;
    } else {
      endOfLastLongTrip = bikeHist[i][0];
    }
    i++;
  }
  return originDataTimestamp - DateTime.fromISO(endOfLastLongTrip);
}
