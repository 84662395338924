import React from 'react';
// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import {DateTime} from 'luxon'
import {
  distanceInKM
} from './utils.js'
import {
  timeSinceLastLongTrip,
  STATION_UNKNOWN,
} from './historyAnalysis.js'
import './Place.css'
var humanizeDuration = require("humanize-duration")


const humanizer = humanizeDuration.humanizer({
  largest: 1,
  round: true,
  units: ['d', 'h', 'm'],
  delimiter: ' ',
  spacer: ' ',
  language: 'shortEn',
  languages: {
    shortEn: {
      d: () => 'd',
      h: () => 'h',
      m: () => 'min',
    }
  }
});

export default class Place extends React.PureComponent {
  render () {
    const place = this.props.place;
    const showHistory = this.props.showHistory;
    if (place == null) {
      return null;
    }
    return (
      <div className='Place'>
        <h3>{place.number} - {place.name}</h3>
        <input type="button" onClick={this.props.toggleShowHistory}
          value={showHistory ? 'Hide history' : 'Show history'} />

        <Table className="bikesTable" style={{tableLayout:"auto", width: "auto"}}>
       <TableHead>
         <TableRow>
           <TableCell className="bikesTableHeaderCell">Number</TableCell>
           <TableCell className="bikesTableHeaderCell">Time since last ride</TableCell>
           {showHistory ? <TableCell colSpan="5">History</TableCell> : null}
         </TableRow>
       </TableHead>
       <TableBody>
        {place.bike_list.map((bike) =>
          <Bike key={bike.number}
          bike={bike}
          history={this.props.bikeHistories[bike.number]}
          currentStation={place.uid}
          originDataTimestamp={this.props.originDataTimestamp}
          placeDistanceFunc={this.props.placeDistanceFunc}
          thresholds={this.props.parkedPeriodThresholds}
          showHistory={showHistory}
          />)}
          </TableBody>
        </Table>
      </div>
    )
  }
}

class ParkedPeriod extends React.PureComponent {
  render () {
    return (
      <TableCell className="bikePeriodParked bikePeriod">
      <i className="material-icons">local_parking</i>
      <div>
        {humanizer(this.props.duration)}
      </div>
      </TableCell>
    )
  }
}

class TripPeriod extends React.PureComponent {
  render() {
    return (
      <TableCell className="bikePeriodTrip bikePeriod">
      <i className="material-icons">arrow_back</i>
      <div>
        {humanizer(this.props.duration)}<br />
        {this.props.distance != null ? distanceInKM(this.props.distance): null}
      </div>
      </TableCell>
    )
  }
}

class Bike extends React.PureComponent {
  lastPeriodsDescription(history, currentStation, originDataTimestamp, placeDistanceFunc) {
    if (history == null || history.length < 1 || (history.length < 2 && history[0][1] !== currentStation)) {
      return [];
    }
    if (history[0][1] === currentStation) {
      let periodStart = DateTime.fromISO(history[0][0]);
      return (
        <React.Fragment>
        <ParkedPeriod key={periodStart} duration={originDataTimestamp - periodStart} />
        </React.Fragment>
      )


    } else {
      let lastParked = history.find((time, station_id) => station_id !== STATION_UNKNOWN);
      let tripDistance = placeDistanceFunc(lastParked[1], currentStation);
      let tripDuration;
      if (history[0][1] === STATION_UNKNOWN) {
        tripDuration = originDataTimestamp - DateTime.fromISO(history[1][0]);
      } else {
        tripDuration = 0;
      }
      return (
        <React.Fragment>
        <ParkedPeriod key={originDataTimestamp} duration={0} />
        <TripPeriod key={history[0][0]} duration={tripDuration} distance={tripDistance} />
        </React.Fragment>
      )
    }
  }

  historyPeriodsDescription(history, placeDistanceFunc) {
    try {
      let periods = [];
      let i = 0;

      while (history && i < history.length) {
        if (history[i][1] === STATION_UNKNOWN) {
          i++;
          continue;
        }
        if (i+2 >= history.length) {
          break; // not enough info to describe a period
        }
        if (history[i+1][1] !== STATION_UNKNOWN) {
          periods.push(<TripPeriod key={history[i][0]} duration={null} distance={placeDistanceFunc(history[i][1], history[i+1][1])} />);
          i++;
          continue;
        }
        let tripDuration = DateTime.fromISO(history[i][0]) - DateTime.fromISO(history[i+1][0]);
        let tripDistance = placeDistanceFunc(history[i][1], history[i+2][1]);
        let parkedDuration = DateTime.fromISO(history[i+2][0]) - DateTime.fromISO(history[i+1][0]);
        periods.push(<TripPeriod key={history[i][0]} duration={tripDuration} distance={tripDistance} />);
        periods.push(<ParkedPeriod key={history[i+1][0]} duration={parkedDuration} />);
        i++;
      }
      return (
        <React.Fragment>
        {periods}
        </React.Fragment>
      );
    } catch(error) {
      console.log("ERROR:", error);
    }
  }

  bikeHealth(history, thresholds, originDataTimestamp, distanceFunc) {
    const parkedPeriod = timeSinceLastLongTrip(history, originDataTimestamp, distanceFunc);
    let status;
    if (parkedPeriod === null) {
      status = "Unknown";
    } else if (parkedPeriod > thresholds.t_high) {
      status = "Low";
    } else if (parkedPeriod > thresholds.t_low) {
      status = "Medium";
    } else {
      status = "Good";
    }

    const statusClassName = "bikeStatus bikeStatus" + status
    return (
      <TableCell className={statusClassName}>
        <p>{humanizer(parkedPeriod)}</p>
      </TableCell>
    );
  }

  render() {
    const bike = this.props.bike;
    const history = this.props.history;
    const currentStation = this.props.currentStation;
    const originDataTimestamp = this.props.originDataTimestamp;
    const distanceFunc = this.props.placeDistanceFunc;
    const showHistory = this.props.showHistory;
    return (
      <TableRow key={bike.number} className="bikeRow">
      <TableCell component="th" scope="row" align="center">{bike.number}</TableCell>
      {this.bikeHealth(history, this.props.thresholds, originDataTimestamp, distanceFunc)}
      {showHistory ? this.lastPeriodsDescription(history, currentStation, originDataTimestamp, distanceFunc):null}
      {showHistory ? this.historyPeriodsDescription(history, distanceFunc):null}
      </TableRow>
    )
  }

}

//
//-
